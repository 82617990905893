import { wordWrap } from "@/utils";
import settings from "@/settings"
export const policy = [
    wordWrap(`本产品保修期为${settings.repairDate}年，但下列情况除外：
    1.自购买之日起超过${settings.repairDate}年的；
    2.无产品保修卡和购买凭证的（但能证明该产品在保修期内的除外）；
    3.产品保修卡和购买凭证与产品不相符或者涂改的；
    4.人为因素（包括不可抗力、非正常工作环境、不按说明书操作引起的扭坏等）导致本产品无法使用的；
    5.用户私自拆装、自行维修、改装或者非本公司授权单位维修的；
    6.由于电池原因导致的；`),
    wordWrap(`1.致电${settings.company}统一售后电话或关注公司公众号“${settings.name}安全服务”发起售后请求或联系本公司的全国联保网络的任一联保点进行保修；
    2.要求保修时必须提供保修卡和有效的购买凭证；
    3.在保修范围内、对于符合条件的产品，我们实现免费维修及更换配件；对于非保修范围内的产品，我们提供维修服务并收取相应材料成本费和上门服务费。`)
]
export const standard = [
    wordWrap(`维修服务费合计=上门里程费用+部件置换费用+调试费用
    里程计算规则：按100元/次计费`),
    wordWrap(`市区：200元
    郊区：300元`),
    wordWrap(`市区：300元
    郊区：400元`)
]
