<template>
    <div class="container">
        <van-tabs v-model:active="active" color="#FFD600" sticky animated>
            <van-tab title="服务政策">
                <div class="content">
                    <van-cell title="保修说明：" />
                    <div class="text">
                        <div class="title">
                            一.购买
                            {{ name }}
                            锁的用户，将根据国家有关法规享受三包服务。
                        </div>
                    </div>
                    <div class="text">
                        <div class="title">二.保修原则</div>
                        <div v-html="policy[0]" />
                    </div>
                    <div class="text">
                        <div class="title">三.保修方法</div>
                        <div v-html="policy[1]" />
                    </div>
                </div>
            </van-tab>
            <van-tab title="收费标准">
                <div class="content">
                    <van-cell title="保外服务费用标准：" />
                    <div v-html="standard[0]" class="text" />
                    <van-cell title="费用指导说明：" />
                    <van-image
                        :src="rule_img"
                        class="text"
                        @click="previewImageLocal(rule_img)"
                    />
                    <van-cell title="增值服务：" />
                    <div class="text">
                        <div class="title">移机单安装全包：</div>
                        <div v-html="standard[1]" />
                    </div>
                    <div class="text">
                        <div class="title">移机单安装全包：</div>
                        <div v-html="standard[2]" />
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { previewImageLocal } from "@/utils";
import { policy, standard } from "./json";
import settings from "@/settings";

export default {
    name: "Policy",
    setup() {
        let state = reactive({
            active: 0,
            rule_img: require("@/assets/policy/rule.jpg"),
        });

        state.active = Number(useRoute().query?.active || 0);

        return {
            ...toRefs(state),
            policy,
            standard,
            name: settings.name,

            previewImageLocal,
        };
    },
};
</script>

<style lang="less" scoped>
.container {
    background-color: #f2f2f2;
    flex: 1;
    .content {
        padding: 0 10px;
        background-color: #ffffff;
        .text {
            color: #999;
            padding: 10px 16px;
            font-size: 13px;
            line-height: 1.5;
            .title {
                color: #333;
                font-size: 14px;
                padding-bottom: 5px;
            }
        }
    }
}
</style>